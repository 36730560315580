@charset "utf-8";

//http://paletton.com/#uid=1000u0k00q6v71NQp3yMSe7KYkR
//$color-primary-0: #D0D0D0;	// Main Primary color */
//$color-primary-1: #0E0000;
//$color-primary-2: #1C0000;
//$color-primary-3: #710000;
//$color-primary-4: #A60000;


// Our variables
$base-font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  0.875rem;
$base-line-height: 1.5;

$spacing-unit:     2rem;

//$text-color:       #111;
//$background-color: #fdfdfd;
//$brand-color:      #2a7ae2;

$background-color: #FDFDFD;	// Main Primary color */
$text-color: #0E0000;
$color-primary-2: #1C0000;
$color-primary-3: #710000;
$brand-color: #A60000;

$grey-color: #000000;
$grey-color-light: #710000;
$grey-color-dark: #1C0000;

//$grey-color:       #828282;
//$grey-color-light: lighten($grey-color, 40%);
//$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    35em;

$on-palm:          20em;
$on-laptop:        30em;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 3rem;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
  float: right;
  line-height: 3rem;

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
		margin-bottom: 0;

    li {
      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      a {
        color: $text-color;
        line-height: $base-line-height;
      }
    }
  }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.social-media-list dt {
	float: left;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: calc(40% - (#{$spacing-unit} / 2));
}

/*
@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-laptop) {
    .footer-col {
        float: none;
        width: calc(100% - (#{$spacing-unit} / 2));
    }
}

*/

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 1.75rem;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 2.25rem;
    letter-spacing: -1px;
    line-height: 1;
/*
    @include media-query($on-laptop) {
        font-size: 36px;
    }*/
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 2rem;
/*
        @include media-query($on-laptop) {
            font-size: 28px;
        }
*/    }

    h3 {
        font-size: 1.75rem;
/*
        @include media-query($on-laptop) {
            font-size: 22px;
        }
*/    }

    h4 {
        font-size: 1.5rem;
/*
        @include media-query($on-laptop) {
            font-size: 18px;
        }
*/    }
}
